/**
 * axios 封装
 */
import axios from "axios";
import {Toast} from 'vant';
import Vue from 'vue';

var vm = new Vue();
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";
//实例axios

let axiosConfig = {
    baseURL: "http://47.108.196.65:9527", //本地
    headers: {
        "Content-Type": "application/json",
    },
}

// if (process.env.NODE_ENV == "production") {
axiosConfig.baseURL = '/api';
//api
// }

const Request = axios.create(axiosConfig);

/**
 * 添加请求拦截器
 */
Request.interceptors.request.use(
    function (config) {
        if (window.localStorage.getItem("access_token")) {
            config.headers['token'] = window.localStorage.getItem("access_token");
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        Toast.fail("请求错误，请稍后重试！");
        return Promise.reject(error);
    }
);

const requestSend = (url, data = null, method = null, success = null, fail = null, error = null, complete = null, isLoading = null) => {
    let options = {
        url: '',
        data: {},
        method: '',
        success: null,
        fail: null,
        error: null,
        complete: null,
        isLoading: isLoading
    }
    //判断参数
    if (typeof url == 'object') {
        options = Object.assign({}, options, url);
    } else {
        options.url = url;
    }
    //参数覆盖
    if (data) {
        options.data = data;
    }
    if (method) {
        options.method = method;
    }
    if (success) {
        options.success = success;
    }
    if (fail) {
        options.fail = fail;
    }
    if (error) {
        options.error = error;
    }
    if (complete) {
        options.complete = complete;
    }
    if (typeof isLoading == 'boolean') {
        options.isLoading = isLoading;
    }
    //有效的请示方式判断
    method = options.method.toLowerCase();
    return new Promise((resolve, reject) => {
        let validMethod = ['get', 'post', 'put', 'delete'];
        if (validMethod.indexOf(method) < 0) {
            if (typeof options.error == 'function') {
                options.error('请求方式错误');
            } else {
                Toast.fail("请求方式错误");
            }
            reject('请求方式错误');
        }
        if (options.isLoading) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true
            });
        }
        let requestConfig = {
            url: options.url,
            method: options.method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.localStorage.getItem("access_token") ?? '',
            },
            params: null,
            data: {},
        }
        if (method === 'get') {
            requestConfig.params = options.data;
        } else {
            requestConfig.data = options.data;
        }
        Request.request(requestConfig).then(response => {
            if (options.isLoading) {
                Toast.clear();
            }
            if (response.status === 200) {
                let result = response.data;
                switch (result.status) {
                    case -1:
                    case 401: //需要重新登录
                        if (typeof options.fail == 'function') {
                            options.fail(result);
                        }
                        window.localStorage.clear();
                        window.location.href = "/login";
                        reject('登录失效');
                        break;
                    case 200:
                        if (typeof options.success == 'function') {
                            options.success(result.data, result.msg);
                        } else {
                            resolve(result.data);
                        }
                        break;
                    default:
                        if (result.msg == '登录无效或过期，请重新登录') {
                            window.localStorage.clear();
                            window.location.href = "/login";
                            reject('登录失效');
                        }
                        if (typeof options.fail == 'function') {
                            options.fail(result);
                        } else {
                            Toast(result.msg);
                            reject();
                        }
                }
            } else {
                if (typeof options.error == 'function') {
                    options.error('请求反馈错误');
                } else {
                    reject('请求反馈错误');
                }
            }
            if (typeof options.complete == 'function') {
                options.complete();
            }
        }).catch(err => {
            if (options.isLoading) {
                Toast.clear();
            }
            if (typeof options.error == 'function') {
                options.error(err);
            } else {
                reject(err);
            }
            if (typeof options.complete == 'function') {
                options.complete();
            }
        });
    }).catch(() => {
    });
}

/**
 * 接口请求
 * @type {{post: (function(*=, *=, *=, *=, *=, *=): Promise<unknown>), get: (function(*=, *=, *=, *=, *=, *=): Promise<unknown>), delete: (function(*=, *=, *=, *=, *=, *=): Promise<unknown>), put: (function(*=, *=, *=, *=, *=, *=): Promise<unknown>)}}
 */
const request = {
    getBaseUrl: () => {
        return axiosConfig.baseURL;
    },
    baseUrl: axiosConfig.baseURL,
    get: (url, data = null, success = null, fail = null, error = null, complete = null, isLoading = false) => {
        return requestSend(url, data, 'get', success, fail, error, false);
    },
    post: (url, data = null, success = null, fail = null, error = null, complete = null, isLoading = false) => {
        return requestSend(url, data, 'post', success, fail, error, false);
    },
    put: (url, data = null, success = null, fail = null, error = null, complete = null, isLoading = false) => {
        return requestSend(url, data, 'put', success, fail, error, false);
    },
    delete: (url, data = null, success = null, fail = null, error = null, complete = null, isLoading = false) => {
        return requestSend(url, data, 'delete', success, fail, error, false);
    }
}

export default request;