import Vue from 'vue'
  import App from './App.vue'
  import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import Request from '@/utils/http/request'

Vue.config.productionTip = false;
Vue.prototype.request = Request;
Vue.use(Vant)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
