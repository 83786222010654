<template>
  <div id="app" class="main-container">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'Login',
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      let routeName = this.$route.name + '';
      if (routeName.toLowerCase() !== 'login') {
        let token = window.localStorage.getItem("access_token");
        if (!token || token === '') {
          this.$router.push({
            name: 'login',
          });
        } else {
          let userInfo = window.localStorage.getItem('user_info');
          if (userInfo && userInfo !== '') {
            this.$store.commit('setUserInfo', JSON.parse(userInfo));
          }
          let token = window.localStorage.getItem('access_token');
          if (token && token !== '') {
            this.$store.commit('setToken', token);
          }
        }
      } else {
      }
    },
    checkUserStatus() {
      this.request.post({
        url: '/user/getPoints',
        success: (result) => {
          console.log(result);
          if (parseInt(result.isLogin) !== 0) {
            console.log('asdf');
            this.$router.replace({'name': 'login'});
          } else {
            let userInfo = window.localStorage.getItem('user_info');
            if (userInfo && userInfo !== '') {
              userInfo = JSON.parse(userInfo);
              userInfo = Object.assign({}, userInfo, result);
              this.$store.commit('setUserInfo', userInfo);
              window.localStorage.setItem('user_info', JSON.stringify(userInfo));
            }
          }
          if (parseInt(result.isInGame) === 0 && this.$route.name !== 'detail') {
            this.$dialog.confirm({
              title: '游戏提醒',
              message: '检测到你正在进行游戏，是否直接进入'
            }).then(() => {
              let item = Object.assign({}, result.cardTableInfo, {
                tableNumber: result.tableNumber
              });
              window.localStorage.setItem('room', JSON.stringify(item));
              this.$store.commit('setRoom', item);
              this.$router.push({name: 'detail'});
            }).catch(() => {});
          }
        },
        fail: () => {
          this.$router.replace({'name': 'login'});
        }
      });
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== 'login') {
        this.checkUserStatus();
      }
    }
  }
}
</script>
<style lang="less">
html, body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  background-color: #121319;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  background-color: #181a20;

  .van-button--info {
    background-color: #fad335;
    border-color: #fad335;
    color: #181a20;
  }
}

.main-container {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;

  .van-dialog {
    background-color: #1e2329;

    .van-dialog__header {
      color: #ffffff;
    }

    .van-dialog__footer {
      border-top: 1px solid #0b0e11;
    }

    .van-button--default {
      color: #ffffff;
      background-color: #1e2329;
      border-right: 1px solid #0b0e11;
    }

    .van-dialog__confirm {
      color: #fad335;
    }
  }
}

@media screen and (max-width: 750px) {
  html, body {
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  html, body {
    font-size: 12px;
  }
}

@media screen and (max-width: 300px) {
  html, body {
    font-size: 10px;
  }
}
</style>