import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {},
        token: '',
        wsHost: 'ws://47.108.196.65:9527',
        room: {}
        // wsHost: '/ws',
    },
    getters: {
        userInfo(state) {
            return state.userInfo;
        },
        token(state) {
            return state.token;
        },
        room(state) {
            return state.room;
        }
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setToken(state, token) {
            state.token = token;
        },
        setRoom(state, room) {
            state.room = room;
        },
        clear(state) {
            state.userInfo = {};
            state.token = '';
            state.room = {};
        }
    },
    actions: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setToken(state, token) {
            state.token = token;
        },
        setRoom(state, room) {
            state.room = room;
        },
        clear(state) {
            state.userInfo = {};
            state.token = '';
        }
    }
})
